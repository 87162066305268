html, body, #root, .of_community, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;

    background: #1A1F25;
    color: #fff;
}

body > iframe {
    display: none;
}

p {
    margin: unset;
}

.hash_text {
    max-width: 100px;
    display: flex;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    line-height: 12px;
    color: #B7B7B7;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
}

.dialog > div:nth-child(3) > div {
    max-width: 800px;
    width: 100%;
    border-radius: 5px;
    background: #1b1b1b;
    margin: unset;
    padding: 20px 0;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 16px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #757575;
}
